import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Terms from "@/pages/Terms";
import QA from "@/pages/QA";

import Dashboard from '@/pages/Dashboard/Dashboard';
import Profile from '@/pages/Profile';

Vue.use(Router);

export const constantRoutes = [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta : { title : 'Login'}
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta : { title : 'T&C'}
    },
    {
        path: '/qa',
        name: 'QA',
        component: QA,
        meta : { title : 'Q&A'}
    },
    {
    path: '/',
    redirect: '/dashboard',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta : { title : 'Dashboard'}
      },
        {
            path: 'profile',
            name: 'Profile',
            component: Profile,
            meta : { title : 'Profile'}
        },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ];

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
